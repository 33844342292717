<script setup lang="ts">
import { computed, nextTick } from "vue";
import router from "@/router";

const info = computed(() => {
  if (window.location.href.includes("//localhost:")) return "LOCAL";
  if (window.location.href.includes("pr.ecca.cloud")) return "PR";
  if (window.location.href.includes("dev.ecca.cloud")) return "DEV";
  return "";
});

const setTitle = async () => {
  await nextTick();

  let prNumberTitle = "";
  if (info.value === "PR") {
    const match = window.location.href.match(/ui-(\d+)\.pr\.ecca\.cloud/);
    if (match) {
      prNumberTitle = " " + match[1];
    }
  }

  const titlePrefix = info.value ? `[${info.value}${prNumberTitle}] ` : "";

  if (!document.title.includes(titlePrefix)) {
    document.title = `${titlePrefix} ${document.title}`;
  }
};

router.afterEach(() => {
  setTitle();
});
</script>

<template>
  <div
    v-if="info !== ''"
    class="debug-info"
    :class="`debug-info--${info.toLowerCase()}`"
  >
    {{ info }}
  </div>
</template>

<style scoped lang="scss">
.debug-info {
  position: fixed;
  bottom: 0;
  left: 0;
  color: white;
  font-size: 10px;
  padding: 0.5rem 6rem;
  z-index: 9999;
  border-top-right-radius: 10px;
  font-weight: bold;

  &--local {
    background-color: #8e0000;
  }
  &--dev {
    background-color: #5a21af;
  }
  &--pr {
    background-color: #a8a61c;
  }
}
</style>
