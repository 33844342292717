<script lang="ts" setup>
import { computed, onMounted } from "vue";
import { useRouter } from "vue-router";
import { queryGenerateWrittenContentWithAI } from "@/api/queries/writtenContent";
import { productRoutesNames } from "@/modules/products/routes";
import { useProductsStore } from "@/modules/products/store";
import {
  Nullable,
  IdType,
  WrittenContent,
  WrittenContentAITask,
} from "@/modules/types";
import { useModelValueWrapper } from "@/shared/hooks/useModelValueWrapper";
import { useSnackbar } from "@/shared/hooks/useSnackbar";

const props = defineProps<{
  dialogVisible?: any;
  position: number;
  taskItem: WrittenContentAITask;
  sklId: Nullable<IdType> | undefined;
  productRecommendationPath: Nullable<string> | undefined;
  writtenContentData: WrittenContent;
}>();

const emit = defineEmits([
  "update:dialogVisible",
  "aiWrittenContentGenerated",
  "update:taskItem",
]);

const productsStore = useProductsStore();

const task = useModelValueWrapper(props, emit, "taskItem");

const dialogVisibleMV = useModelValueWrapper(props, emit, "dialogVisible");

const router = useRouter();
const postData = computed(() => {
  return {
    entity_id: props.taskItem.writtenContentId,
    ref: router.resolve({
      name: productRoutesNames.writtenContentEditor.adaptations(),
      params: {
        productId: props.taskItem.productId,
        writtenContentLocalizationId: props.taskItem.writtenContentParentId,
        writtenContentAdaptationId: props.taskItem.writtenContentId,
      },
    }).href,
    type: "written_content",
  };
});

const { mutate: generateWrittenContent } = queryGenerateWrittenContentWithAI(
  postData,
  task.value.writtenContentId,
);

const itemName = computed(() => {
  return (
    props.taskItem.productName + " " + props.taskItem.writtenContentData.name
  );
});

const { pushNotification } = useSnackbar();
productsStore.$onAction(({ name, args }) => {
  const [writtenContentID, isSuccess] = args;

  if (
    name === "setWrittenContentAITaskSuccess" &&
    writtenContentID === task.value.writtenContentId
  ) {
    if (isSuccess) {
      pushNotification(
        "success",
        `Content “${itemName.value}” was successfully generated with AI.`,
        {
          timeout: -1,
        },
      );
      productsStore.writtenContentUpdateByThor(task.value.writtenContentId);
      productsStore.removeWrittenContentAITask(task.value.writtenContentId);
    } else {
      pushNotification(
        "error",
        `An error occurred while generating content for “${itemName.value}” using AI.`,
        {
          timeout: -1,
        },
      );
      productsStore.removeWrittenContentAITask(task.value.writtenContentId);
    }
  }
});

onMounted(() => {
  generateWrittenContent();
  task.value.isAiGenerationInProgress = true;
});
</script>
<template>
  <EccaSnackbar
    v-model="dialogVisibleMV"
    :color="'info'"
    :prependIcon="'$StoreBuildingWarehouse'"
    class="processing-ai-snackbar pt-10"
    :style="`transform: translateY(${position * 120}px)`"
    minWidth="620"
  >
    <div class="ecca-snackbar__text font-weight-bold mb-3">
      Content for {{ itemName }} is being generated.
    </div>
    <div>It can take up to 5 minutes.</div>
    <VProgressLinear
      class="processing-ai-snackbar__progress"
      indeterminate
      color="secondary"
    ></VProgressLinear>
  </EccaSnackbar>
</template>

<style scoped>
.processing-ai-snackbar__progress {
  width: 100%;
  margin: 10px 0;
}
</style>
