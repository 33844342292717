const recoRouteBaseName = "reco";

export const recoRoutesNames = {
  root: "reco",
  details: () => `${recoRoutesNames.root}.details`,
};

export const recoRoutes = (basePath: string): any => {
  const path = `${basePath}/${recoRouteBaseName}`;
  return {
    path,
    component: () => import("@/modules/reco/views/layouts/Default.vue"),
    meta: {
      auth: true,
    },
    children: [
      {
        path: `${path}/:id`,
        name: recoRoutesNames.details(),
        component: () => import("@/modules/reco/views/RecoDetails.vue"),
      },
    ],
  };
};
